@import "variables_custom";
@import '~bootstrap/scss/mixins/breakpoints';

/*
 * Base structure
 */

html,
body {
  height: 100%;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%; /* For at least Firefox */
  min-height: 100%;
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.header-wrapper {
  min-height: 480px;
}

.header-container {
  text-align: center;
  color: white;
}

.header-content {
  display: block;
  position: relative;
  padding: .5rem 2rem;
  min-height: 180px;
  background: no-repeat center center;
  background-size: cover;

  @include media-breakpoint-up(md) {
    min-height: initial;
  }

  .header-container {
    z-index: 2;
  }

  &.overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparentize(black, 0.7);
      z-index: 1;
      transition: background 300ms linear;
    }
  }

  h2.header-gepc-title {
    font-size: 1.2rem;
  }
  


}

a.header-content {
  &:hover {
    text-decoration: none;
    color: white;

    &.overlay {
      &:before {
        background: transparentize(theme-color('primary'), 0.5);
      }
    }
  }
}

.header-content-1a {
  background: url("/images/public/index/header-1a.jpg") no-repeat center center;
  background-size: cover;
}

.header-content-1b {
  background: url("/images/public/index/header-1b.jpg") no-repeat center center;
  background-size: cover;
}

.header-content-1c {
  background: url("/images/public/index/header-1c.jpg") no-repeat center center;
  background-size: cover;
}

/* .header-content-2 {
  background: url("/images/public/index/header-2.jpg") no-repeat center center;
  background-size: cover;
} */

.header-content-3 {
  background: url("/images/public/index/header-3.jpg") no-repeat center center;
  background-size: cover;

  .buttons {
    @include media-breakpoint-up(md) {
      padding-top: 7rem;
    }
  }
}



.header-content-4 {
  // background: url("/images/public/index/header-4.jpg") no-repeat center center;
  background-size: cover;


  .main-text
  {
      position: absolute;
      width: 100%;
      color: #FFF;
  }

 
  @include media-breakpoint-up(lg) {
    .main-text
    {
        top: 0%;
    }
    .titleh1 {
      font-size: 4.5rem;
    }

  }

  @include media-breakpoint-down(sm) {
    .main-text
    {
        top: 0%;
    }
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
    a {
      font-size: 0.8rem;
    }
  }

  @include media-breakpoint-only(md) {
    .main-text
    {
        top: 0%;
    }
    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
    a {
      font-size: 1rem;
    }
  }
}

.header-content-5 {
  background: url("/images/public/index/header-5.jpg") no-repeat center center;
  background-size: cover;
}

@include media-breakpoint-up(md) {
  .full-height {
    height: 100%;
  }

  .half-height {
    height: 50%;
  }
}



.book {
  background: url("/images/public/index/book.jpg") center;
  background-size: cover;
}

@include media-breakpoint-up(sm) {
  .heading-absolute {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 3;
  }

  .full-height {
    height: 100%;
  }


  .hover-reveal {
    position: relative;
    height: 100%;

    &:hover {
      text-decoration: none;
      color: $body-color;
      .hover-reveal-content{
        opacity: 1;
      }
    }

    .objectives-img {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .hover-reveal-title {
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 2;
      color: $gray-200;

      img {
        max-height: 2rem;
        max-width: 2rem;
        display: inline;
        margin-top: -0.2rem;
      }
    }

    .hover-reveal-content {
      min-height: 380px;
      height: 100%;
      padding: 60px 30px 30px 30px;
      opacity: 0;
      transition: opacity 300ms linear;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0.9) 100%);
      z-index: 2;
      position: relative;
      p {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .heading-absolute {
    padding-top: 80px;
  }

  .full-height {
    height: 100%;
  }

  .hover-reveal {
    color: theme-color('dark');
    .hover-reveal-title {
      margin-top: 3rem;
      img {
        filter: brightness(0);
        max-height: 2rem;
        max-width: 2rem;
        display: inline;
        margin-top: -0.2rem;
      }
    }
    .hover-reveal-content {
      position: relative;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.objectives-img {
  @include media-breakpoint-up(lg) {

  }
  @include media-breakpoint-up(xl) {
    //margin-top: 4rem;
  }
}





.work-areas {
  .work-area {
    img {
      max-height: 100px;
      filter: brightness(0) invert(1);
      margin-bottom: 2rem;
    }
    p {
      color: white;
      margin: 0;
      @include media-breakpoint-down(xs) {
        //font-size: 0.95rem;
      }
    }
  }

  .work-areas-carousel {
    margin-top: 4rem;
    .carousel-control-prev, .carousel-control-next {
      width: 50px;
    }
    min-height: 195px;
    .carousel-inner {
      min-height: 195px;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 3rem;
        padding-left: 3rem;
    }
  }
}

.row-promoters {
  img {
    display: inline;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    width: 180px;
    @include media-breakpoint-up(sm) {
      width: 250px;
    }
    @include media-breakpoint-up(lg) {
      width: 300px;
    }
    @include media-breakpoint-up(xl) {
      width: 16%;
    }
  }

  &.row-promoters-lead {
    img {
      @include media-breakpoint-up(sm) {
        height: 60px;
        width: auto;
      }
      @include media-breakpoint-up(lg) {
        height: 70px;
        width: auto;
      }
      @include media-breakpoint-up(xl) {
        height: 80px;
        width: auto;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .row-promoters {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}


.header-scroll {
  z-index: 5;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  text-decoration: none;
  color: white;
  &:hover,
  &:focus,
  &:visited {
    text-decoration: none;
  }
  .fa {
    opacity: 0;
    display: block;
    margin-top: -46px;
    animation: header-scroll 1s infinite;
    color: white;
    &:nth-child(2) {
      animation: header-scroll 1s infinite 200ms;
      color: white;
    }
    &:nth-child(3) {
      animation: header-scroll 1s infinite 400ms;
      color: white;
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

@keyframes header-scroll {
  0%   { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

.fill-primary path {
  fill: theme-color("primary");
}
.fill-secondary path {
  fill: theme-color("secondary");
}
.fill-success path {
  fill: theme-color("success");
}
.fill-danger path {
  fill: theme-color("danger");
}
.fill-warning path {
  fill: theme-color("warning");
}
.fill-info path {
  fill: theme-color("info");
}
.fill-light path {
  fill: theme-color("light");
}
.fill-dark path {
  fill: theme-color("dark");
}
.fill-white path {
  fill: theme-color("white");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 1rem;
  z-index: 15;
  display: flex;
  justify-content: left;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 1%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
}


.rounded-2{
  border-radius: 2rem;
  border: 0px;

}



.cover-video {
  height: 100%;
  min-height: 600px;
  position: relative;
  top: -1px;
  width: 100%;
  overflow: hidden;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    min-height: 62rem;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100.1%;
    min-height: 100.1%;
    width: auto;
    height: auto;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }

  .video-cover-caption {
    position: absolute;
    margin-top: -440px;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    h1 {
      font-size: 4rem;
    }
  }

  .video-cover-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparentize(black, 0.60);
    background-image: url("/images/public/video_overlay.png");
    z-index: 1;
  } 

  .link-circle .circle-links {
    margin-top: 2rem;
  }

  .link-circle{
    margin-bottom: 20px;
    margin-left: 20px;
    display: block;
    background-color: #009fdf;;
    color: #fff;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
      border-radius: 4px;
      width: 20rem;
      height: 4rem;
      line-height: 4rem;
    }
    @include media-breakpoint-up(sm) {
      border-radius: 4px;
      width: 11rem;
      height: 4rem;
      line-height: 4rem;
    }
    @include media-breakpoint-up(md) {
      width: 220px;
      height: 220px;
      line-height: 220px;
      border-radius: 110px;
      left: calc(50% - 110px);
      position: absolute;
    }
    top: 0;
    transition: width .1s linear,height .1s linear,top .1s linear,left .1s linear,line-height .1s linear,border-radius .1s linear;
    &:hover {
      text-decoration: none;
      opacity: 0.5;
    }
  }

  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  ul.countdown {
      list-style: none;
      padding: 0;
      display: block;
      text-align: center;
  }
  ul.countdown li {
      display: inline-block;
  }
  ul.countdown li span {
      font-size: 80px;
      font-weight: 300;
      line-height: 80px;
      @include media-breakpoint-down(lg) {
        font-size: 40px;
        font-weight: 300;
        line-height: 40px;
      }
  }
  ul.countdown li.separator {
      font-size: 80px;
      line-height: 70px;
      vertical-align: top;
      @include media-breakpoint-down(lg) {
        font-size: 40px;
        font-weight: 300;
        line-height: 30px;
      }
  }
  ul.countdown li p {
      color: #a7abb1;
      font-size: 14px;
  }
}
.call-text {
  @include media-breakpoint-down(lg) {
    font-size: 1.5rem;
  }
}