@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
.text-blue {
  color: #0981AC;
}

.bg-blue {
  background-color: #0981AC;
}

.border-blue {
  border-color: #0981AC !important;
}

.text-indigo {
  color: #6610f2;
}

.bg-indigo {
  background-color: #6610f2;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.text-purple {
  color: #6f42c1;
}

.bg-purple {
  background-color: #6f42c1;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.text-pink {
  color: #e83e8c;
}

.bg-pink {
  background-color: #e83e8c;
}

.border-pink {
  border-color: #e83e8c !important;
}

.text-red {
  color: #CF4444;
}

.bg-red {
  background-color: #CF4444;
}

.border-red {
  border-color: #CF4444 !important;
}

.text-orange {
  color: #fd7e14;
}

.bg-orange {
  background-color: #fd7e14;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.text-yellow {
  color: #EBBB54;
}

.bg-yellow {
  background-color: #EBBB54;
}

.border-yellow {
  border-color: #EBBB54 !important;
}

.text-green {
  color: #94BA65;
}

.bg-green {
  background-color: #94BA65;
}

.border-green {
  border-color: #94BA65 !important;
}

.text-teal {
  color: #20c997;
}

.bg-teal {
  background-color: #20c997;
}

.border-teal {
  border-color: #20c997 !important;
}

.text-cyan {
  color: #0981AC;
}

.bg-cyan {
  background-color: #0981AC;
}

.border-cyan {
  border-color: #0981AC !important;
}

.text-white {
  color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.border-white {
  border-color: #FFFFFF !important;
}

.text-morado {
  color: #6662AC;
}

.bg-morado {
  background-color: #6662AC;
}

.border-morado {
  border-color: #6662AC !important;
}

.text-gray {
  color: #e9ecef;
}

.bg-gray {
  background-color: #e9ecef;
}

.border-gray {
  border-color: #e9ecef !important;
}

.text-darkgray {
  color: #4D4D4D;
}

.bg-darkgray {
  background-color: #4D4D4D;
}

.border-darkgray {
  border-color: #4D4D4D !important;
}

.text-bluebf {
  color: #009fdf;
}

.bg-bluebf {
  background-color: #009fdf;
}

.border-bluebf {
  border-color: #009fdf !important;
}

.text-bluebf-light {
  color: #dbf1f9;
}

.bg-bluebf-light {
  background-color: #dbf1f9;
}

.border-bluebf-light {
  border-color: #dbf1f9 !important;
}

.text-bluebf-mid {
  color: #39b2dd;
}

.bg-bluebf-mid {
  background-color: #39b2dd;
}

.border-bluebf-mid {
  border-color: #39b2dd !important;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
}

/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  /* For at least Firefox */
  min-height: 100%;
}

.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}

.header-wrapper {
  min-height: 480px;
}

.header-container {
  text-align: center;
  color: white;
}

.header-content {
  display: block;
  position: relative;
  padding: .5rem 2rem;
  min-height: 180px;
  background: no-repeat center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .header-content {
    min-height: initial;
  }
}

.header-content .header-container {
  z-index: 2;
}

.header-content.overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition: background 300ms linear;
}

.header-content h2.header-gepc-title {
  font-size: 1.2rem;
}

a.header-content:hover {
  text-decoration: none;
  color: white;
}

a.header-content:hover.overlay:before {
  background: rgba(9, 129, 172, 0.5);
}

.header-content-1a {
  background: url("/images/public/index/header-1a.jpg") no-repeat center center;
  background-size: cover;
}

.header-content-1b {
  background: url("/images/public/index/header-1b.jpg") no-repeat center center;
  background-size: cover;
}

.header-content-1c {
  background: url("/images/public/index/header-1c.jpg") no-repeat center center;
  background-size: cover;
}

/* .header-content-2 {
  background: url("/images/public/index/header-2.jpg") no-repeat center center;
  background-size: cover;
} */
.header-content-3 {
  background: url("/images/public/index/header-3.jpg") no-repeat center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .header-content-3 .buttons {
    padding-top: 7rem;
  }
}

.header-content-4 {
  background-size: cover;
}

.header-content-4 .main-text {
  position: absolute;
  width: 100%;
  color: #FFF;
}

@media (min-width: 992px) {
  .header-content-4 .main-text {
    top: 0%;
  }
  .header-content-4 .titleh1 {
    font-size: 4.5rem;
  }
}

@media (max-width: 767px) {
  .header-content-4 .main-text {
    top: 0%;
  }
  .header-content-4 h1 {
    font-size: 2rem;
  }
  .header-content-4 h3 {
    font-size: 1rem;
  }
  .header-content-4 p {
    font-size: 0.8rem;
  }
  .header-content-4 a {
    font-size: 0.8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-content-4 .main-text {
    top: 0%;
  }
  .header-content-4 h1 {
    font-size: 3rem;
  }
  .header-content-4 h3 {
    font-size: 1.5rem;
  }
  .header-content-4 p {
    font-size: 1rem;
  }
  .header-content-4 a {
    font-size: 1rem;
  }
}

.header-content-5 {
  background: url("/images/public/index/header-5.jpg") no-repeat center center;
  background-size: cover;
}

@media (min-width: 768px) {
  .full-height {
    height: 100%;
  }
  .half-height {
    height: 50%;
  }
}

.book {
  background: url("/images/public/index/book.jpg") center;
  background-size: cover;
}

@media (min-width: 576px) {
  .heading-absolute {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 3;
  }
  .full-height {
    height: 100%;
  }
  .hover-reveal {
    position: relative;
    height: 100%;
  }
  .hover-reveal:hover {
    text-decoration: none;
    color: #212529;
  }
  .hover-reveal:hover .hover-reveal-content {
    opacity: 1;
  }
  .hover-reveal .objectives-img {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .hover-reveal .hover-reveal-title {
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    color: #e9ecef;
  }
  .hover-reveal .hover-reveal-title img {
    max-height: 2rem;
    max-width: 2rem;
    display: inline;
    margin-top: -0.2rem;
  }
  .hover-reveal .hover-reveal-content {
    min-height: 380px;
    height: 100%;
    padding: 60px 30px 30px 30px;
    opacity: 0;
    transition: opacity 300ms linear;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.9) 100%);
    z-index: 2;
    position: relative;
  }
  .hover-reveal .hover-reveal-content p {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .heading-absolute {
    padding-top: 80px;
  }
  .full-height {
    height: 100%;
  }
  .hover-reveal {
    color: #343a40;
  }
  .hover-reveal .hover-reveal-title {
    margin-top: 3rem;
  }
  .hover-reveal .hover-reveal-title img {
    filter: brightness(0);
    max-height: 2rem;
    max-width: 2rem;
    display: inline;
    margin-top: -0.2rem;
  }
  .hover-reveal .hover-reveal-content {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.work-areas .work-area img {
  max-height: 100px;
  filter: brightness(0) invert(1);
  margin-bottom: 2rem;
}

.work-areas .work-area p {
  color: white;
  margin: 0;
}

.work-areas .work-areas-carousel {
  margin-top: 4rem;
  min-height: 195px;
}

.work-areas .work-areas-carousel .carousel-control-prev, .work-areas .work-areas-carousel .carousel-control-next {
  width: 50px;
}

.work-areas .work-areas-carousel .carousel-inner {
  min-height: 195px;
}

@media (min-width: 1200px) {
  .work-areas .work-areas-carousel {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.row-promoters img {
  display: inline;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  width: 180px;
}

@media (min-width: 576px) {
  .row-promoters img {
    width: 250px;
  }
}

@media (min-width: 992px) {
  .row-promoters img {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .row-promoters img {
    width: 16%;
  }
}

@media (min-width: 576px) {
  .row-promoters.row-promoters-lead img {
    height: 60px;
    width: auto;
  }
}

@media (min-width: 992px) {
  .row-promoters.row-promoters-lead img {
    height: 70px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .row-promoters.row-promoters-lead img {
    height: 80px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .row-promoters {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.header-scroll {
  z-index: 5;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  text-decoration: none;
  color: white;
}

.header-scroll:hover, .header-scroll:focus, .header-scroll:visited {
  text-decoration: none;
}

.header-scroll .fa {
  opacity: 0;
  display: block;
  margin-top: -46px;
  animation: header-scroll 1s infinite;
  color: white;
}

.header-scroll .fa:nth-child(2) {
  animation: header-scroll 1s infinite 200ms;
  color: white;
}

.header-scroll .fa:nth-child(3) {
  animation: header-scroll 1s infinite 400ms;
  color: white;
}

@media (max-width: 767px) {
  .header-scroll {
    display: none;
  }
}

@keyframes header-scroll {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.fill-primary path {
  fill: #0981AC;
}

.fill-secondary path {
  fill: #868e96;
}

.fill-success path {
  fill: #94BA65;
}

.fill-danger path {
  fill: #CF4444;
}

.fill-warning path {
  fill: #EBBB54;
}

.fill-info path {
  fill: #0981AC;
}

.fill-light path {
  fill: #f8f9fa;
}

.fill-dark path {
  fill: #343a40;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 1rem;
  z-index: 15;
  display: flex;
  justify-content: left;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 1%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
}

.rounded-2 {
  border-radius: 2rem;
  border: 0px;
}

.cover-video {
  height: 100%;
  min-height: 600px;
  position: relative;
  top: -1px;
  width: 100%;
  overflow: hidden;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
}

@media (max-width: 1199px) {
  .cover-video {
    min-height: 62rem;
  }
}

.cover-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100.1%;
  min-height: 100.1%;
  width: auto;
  height: auto;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

.cover-video .video-cover-caption {
  position: absolute;
  margin-top: -440px;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.cover-video .video-cover-caption h1 {
  font-size: 4rem;
}

.cover-video .video-cover-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: url("/images/public/video_overlay.png");
  z-index: 1;
}

.cover-video .link-circle .circle-links {
  margin-top: 2rem;
}

.cover-video .link-circle {
  margin-bottom: 20px;
  margin-left: 20px;
  display: block;
  background-color: #009fdf;
  color: #fff;
  text-decoration: none;
  top: 0;
  transition: width .1s linear,height .1s linear,top .1s linear,left .1s linear,line-height .1s linear,border-radius .1s linear;
}

@media (max-width: 767px) {
  .cover-video .link-circle {
    border-radius: 4px;
    width: 20rem;
    height: 4rem;
    line-height: 4rem;
  }
}

@media (min-width: 576px) {
  .cover-video .link-circle {
    border-radius: 4px;
    width: 11rem;
    height: 4rem;
    line-height: 4rem;
  }
}

@media (min-width: 768px) {
  .cover-video .link-circle {
    width: 220px;
    height: 220px;
    line-height: 220px;
    border-radius: 110px;
    left: calc(50% - 110px);
    position: absolute;
  }
}

.cover-video .link-circle:hover {
  text-decoration: none;
  opacity: 0.5;
}

.cover-video .center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.cover-video ul.countdown {
  list-style: none;
  padding: 0;
  display: block;
  text-align: center;
}

.cover-video ul.countdown li {
  display: inline-block;
}

.cover-video ul.countdown li span {
  font-size: 80px;
  font-weight: 300;
  line-height: 80px;
}

@media (max-width: 1199px) {
  .cover-video ul.countdown li span {
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
  }
}

.cover-video ul.countdown li.separator {
  font-size: 80px;
  line-height: 70px;
  vertical-align: top;
}

@media (max-width: 1199px) {
  .cover-video ul.countdown li.separator {
    font-size: 40px;
    font-weight: 300;
    line-height: 30px;
  }
}

.cover-video ul.countdown li p {
  color: #a7abb1;
  font-size: 14px;
}

@media (max-width: 1199px) {
  .call-text {
    font-size: 1.5rem;
  }
}
